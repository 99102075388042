.login-box {
    width: 100%;
    height: 100%;
    text-align: center;
    position: absolute;
    background: #001529;
}

.login-container {
    -webkit-border-radius: 5px;
    border-radius: 5px;
    -moz-border-radius: 5px;
    background-clip: padding-box;
    margin: 150px auto;
    width: 350px;
    padding: 35px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    box-shadow: 0 0 25px #cac6c6;
}

.subLogin {
    margin: 30px 0;
}